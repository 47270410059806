
import { Component, Vue, Watch } from "vue-property-decorator";
import Purenavbar from "@/components/Utility/Purenavbar.vue";
import Loading from "@/components/Utility/Loading.vue";
import Authorization from "../models/Authorization";
//import tigermaster from "fdtigermaster-client-sdk";
//import User from "fdtigermaster-client-sdk/lib/src/User/User";
//import IUser from "fdtigermaster-client-sdk/lib/src/Entity/IUser";
@Component({
  components: {
    Purenavbar,
    Loading,
  },
})
export default class ResetPassword extends Vue {
  //data
  visibility = "password";
  checkvisibility = "password";
  password = "";
  passwordError = false;
  passwordOk: boolean | null = null;
  passErrMsg = "";
  passOkMsg = "";
  checkpassword = "";
  checkpasswordError: boolean | null = false;
  checkpasswordOk: boolean | null = null;
  checkErrMsg = "";
  checkOkMsg = "";
  compositionStatus = false;
  userid!: string;
  token!: string;
  showinvalid = true;
  showvalid = false;
  showcheckinvalid = true;
  showcheckvalid = false;
  isShow = true;
  isCheck = false;
  isLoading = false;
  @Watch("password")
  private watchPassword() {
    let isText = /^[a-zA-Z0-9]+$/;
    this.checkpassword = "";
    if (this.password === "") {
      this.passwordError = false;
      this.passwordOk = false;
      this.checkpasswordOk = null;
      this.checkpasswordError = false;
      this.showinvalid = true;
    } else if (!isText.test(this.password)) {
      this.passwordError = true;
      this.passwordOk = false;
      this.passErrMsg = "請勿包含特殊字元";
      this.passOkMsg = "";
      this.showinvalid = true;
      this.showvalid = false;
    } else if (this.password.length < 6) {
      this.passwordError = true;
      this.passwordOk = false;
      this.passErrMsg = "請勿少於6個字";
      this.passOkMsg = "";
      this.showinvalid = true;
      this.showvalid = false;
    } else {
      this.passwordError = false;
      this.passwordOk = true;
      this.passOkMsg = "新密碼符合格式要求";
      this.checkErrMsg = "";
      this.checkpasswordOk = null;
      this.checkpasswordError = null;
      this.checkOkMsg = "";
      this.showinvalid = false;
      this.showvalid = true;
    }
  }
  @Watch("checkpassword")
  private watchCheckpassword() {
    let isText = /^[a-zA-Z0-9]+$/;
    if (this.password === "" || !isText.test(this.password) || this.password.length < 6) {
      this.checkpasswordError = false;
      this.checkpassword = "";
      this.checkErrMsg = "";
    } else if (this.password !== "" && this.checkpassword === "") {
      this.checkErrMsg = "";
    } else if (this.password !== this.checkpassword) {
      this.checkpasswordError = true;
      this.checkErrMsg = "與先前的密碼輸入不一致";
      this.checkOkMsg = "";
      this.checkpasswordOk = false;
      this.showcheckinvalid = true;
      this.showcheckvalid = false;
      this.isCheck = false;
    } else if (this.password === this.checkpassword) {
      this.checkpasswordError = false;
      this.checkpasswordOk = true;
      this.checkOkMsg = "與新密碼輸入一致";
      this.showcheckinvalid = false;
      this.showcheckvalid = true;
      this.isCheck = true;
    }
  }

  //methods
  send(): void {
    this.isLoading = true;
    setTimeout(() => {
      this.resetPassword();
    }, 2000);
  }
  async resetPassword(): Promise<void> {
    this.isLoading = false;
    let pass = this.checkpassword;
    let userid = this.$route.query.userId as string;
    let token = this.$route.query.token as string;
    const authorization = new Authorization();
    authorization.resetPassword(userid, token, pass);
    this.isShow = false;
    // await tigermaster.auth.loginWithTempToken(token);
    // const res = await tigermaster.auth.getUserById(userid);
    // res.resetPassword(pass);
  }
  showPassword(): void {
    this.visibility = "text";
  }
  hidePassword(): void {
    this.visibility = "password";
  }
  showcheckPassword(): void {
    this.checkvisibility = "text";
  }
  hidecheckPassword(): void {
    this.checkvisibility = "password";
  }
}
