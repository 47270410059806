class Authorization {
    async resetPassword(userId:string, token:string, pass:string):Promise<void> {
        await fetch(`${process.env.VUE_APP_MODEL_URL}/user/reset_password?userId=${userId}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': token },
            body: JSON.stringify({ pass: pass })
        })
    }
}
export default Authorization
